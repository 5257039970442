import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Today The Ville will have our annual “12 Days of Christmas” WOD at
10:30am.  Everyone is invited!  The gym will be closed other than this
class.  We will be closed tomorrow, December 25th Christmas Day, and
then reopen Wednesday, December 26th starting with our 10:30am class.`}</strong></p>
    <p><strong parentName="p">{`“12 Days Of Christmas”`}</strong></p>
    <p>{`1-SDHP (75/55)(RX+ 95/65)`}</p>
    <p>{`2-Thrusters`}</p>
    <p>{`3-Push Press`}</p>
    <p>{`4-Power Cleans`}</p>
    <p>{`5-Power Snatch`}</p>
    <p>{`6-KBS (53/35)(RX+ 70/53)`}</p>
    <p>{`7-Pullups (RX+ CTB Pullups)`}</p>
    <p>{`8-K2E’s`}</p>
    <p>{`9-Box Jumps (24/20)(RX+ 30/24)`}</p>
    <p>{`10-Double Unders`}</p>
    <p>{`11-Burpees`}</p>
    <p>{`12-OH Walking Lunges (45/25)`}</p>
    <p><em parentName="p">{`*`}{`performed like the song, 1/2-1/3-2-1/4-3-2-1/etc`}</em></p>
    <p><em parentName="p">{`*`}{`*`}{`compare your time to our last 4 Christmas Eve’s`}</em></p>
    <p><strong parentName="p">{`Beginners 12 Days of Christmas`}</strong></p>
    <p>{`1-D Ball Clean (80/40)`}</p>
    <p>{`2-Jump Squats`}</p>
    <p>{`3-Lunges/Leg`}</p>
    <p>{`4-Back Extensions`}</p>
    <p>{`5-Ring Rows`}</p>
    <p>{`6-Burpees`}</p>
    <p>{`7-Wall Balls (20/14)`}</p>
    <p>{`8-V Ups`}</p>
    <p>{`9-Push Ups`}</p>
    <p>{`10-Sit Ups`}</p>
    <p>{`11-KBS’s (45/35)`}</p>
    <p>{`12-DB Push Press (30/20’s)`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s and Sunday’s each weekend after
our CrossFit classes.  Email Daniel for more information.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  You still
have time to register but we only have 5 spots left.  Get your teams
together and registered soon to reserve your spot! `}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w"
      }}>{`https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      